import { LayoutProps } from 'antd/lib/layout';
import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';

const Container = styled(Content)`
    padding: 15px;
    background-color: #e6e6ea;
`;

const StyledContent = (props: LayoutProps): JSX.Element => (
    (<Container>
        {props.children}
    </Container>
    )
);

export default StyledContent;
