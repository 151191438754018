/* eslint-disable curly, nonblock-statement-body-position */
import moment from 'moment';
import { FormProps } from 'antd/lib/form';
import { InputProps } from 'antd/lib/input';
import { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { SelectProps } from 'antd/lib/select';
import { DatePickerProps } from 'antd/lib/date-picker';
import { Select, Form, Input, Row, DatePicker } from 'antd';

import Find from '../models/find';
import CustomCard from './CustomCard';
import constants from '../utils/constants';
import ISearchOption from '../models/searchOption';
import ISelectOption from '../models/selectOption';
import CustomLinkButton from './CustomLinkButton';
import CustomPrimaryButton from './CustomPrimaryButton';

interface IProps extends FormProps {
    options?: Array<ISearchOption>;
    /* eslint-disable-next-line no-unused-vars */
    onFinish?: (values: Find) => void;
}

interface ISelectProps {
    options?: ISelectOption[]
}

const TextSearch = (props: InputProps): JSX.Element => (
    <Input
        type="text"
        size="large"
        placeholder="Search ..."
        style={{
            width: 280
        }}
        {...props}
    />
);

const SelectSearch = (props: SelectProps<any> & ISelectProps): JSX.Element => (
    <Select
        size="large"
        defaultValue={props.options?.[0]?.value}
        style={{
            minWidth: 280
        }}
        {...props}
    >
        {props.options?.map(option => (
            <Select.Option value={option.value} key={option.value}>
                {option.title}
            </Select.Option>
        ))}
    </Select>
);

const DateSearch = (props: DatePickerProps) => (
    <DatePicker
        size="large"
        style={{
            width: 280
        }}
        {...props}
    />
);

const CustomSearch = (props: IProps): JSX.Element => {
    const [form] = useForm();
    const [selectedOption, setSelectedOption] = useState<string>('');

    const clearFitlers = () => {
        form.resetFields();
        setSelectedOption('');
    };

    const handleSubmitForm = (values: Find) => {
        props.onFinish?.({
            findIn: values.findIn,
            find: moment.isMoment(values.find)
                ? moment(values.find).format(constants.DATE.DATABASE_FORMAT)
                : values.find
        });
    };

    const selectOption = (option: string) => {
        form.setFieldsValue({ find: undefined });
        setSelectedOption(option);
    };

    const getInputType = (type: string, avaliableOptions: ISelectOption[]) => {
        if (type === 'text')
            return <TextSearch />;

        if (type === 'select')
            return <SelectSearch options={avaliableOptions}/>;

        if (type === 'date')
            return <DateSearch />;

        return <></>;
    };

    useEffect(() => {
        if (selectedOption === '') {
            form.submit();
        }
    }, [selectedOption]);

    const optionType = props.options?.find(option => option.key === selectedOption)?.type || '';
    const avaliableOptions = props.options?.find(option => option.key === selectedOption)?.selectAvaliableValues || [];

    return (
        <Form
            {...props}
            form={form}
            onFinish={handleSubmitForm}
        >
            <CustomCard
                bodyStyle={{
                    paddingBottom: 0
                }}
            >
                <Row>
                    <Form.Item
                        name="findIn"
                        style={{
                            marginRight: 15
                        }}
                    >
                        <Select
                            size="large"
                            defaultValue={selectedOption}
                            style={{
                                minWidth: 150
                            }}
                            onChange={e => selectOption(e.toString())}
                        >
                            <Select.Option value="">Show All</Select.Option>
                            {props.options?.map(option => (
                                <Select.Option key={option.title} value={option.key}>
                                    {option.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="find"
                        style={{
                            marginRight: 15
                        }}
                    >
                        {getInputType(optionType, avaliableOptions)}
                    </Form.Item>
                    <CustomPrimaryButton
                        htmlType="submit"
                        style={{
                            marginRight: 15
                        }}
                        size="large"
                    >
                        Search
                    </CustomPrimaryButton>
                    <CustomLinkButton
                        size="large"
                        onClick={clearFitlers}
                    >
                        Clear
                    </CustomLinkButton>
                </Row>
            </CustomCard>
        </Form>
    );
};

export default CustomSearch;
