import Layout, { BasicProps } from 'antd/lib/layout/layout';
import { Link, RouteChildrenProps } from 'react-router-dom';
import AdminSiderMenu from '../components/AdminSiderMenu';
import CustomAdminHeader from '../components/templetes/CustomAdminHeader';
import StyledContent from '../styles/StyledContent';
import StyledLayout from '../styles/StyledLayout';
import StyledSider from '../styles/StyledSider';

type Props = RouteChildrenProps & BasicProps;

export default function AdminTemplate(props: Props): JSX.Element {
    return (
        <StyledLayout>
            <StyledSider width={290}>
                <Link to="/">
                    <img src="/resources/images/logo-main-white.svg" />
                </Link>
                <AdminSiderMenu />
            </StyledSider>
            <Layout className="site-layout">
                <CustomAdminHeader {...props} />
                <StyledContent >{props.children}</StyledContent>
            </Layout>
        </StyledLayout>
    );
}
