import { BasicProps } from 'antd/lib/layout/layout';
import styled from 'styled-components';

const StyledImg = styled.img`
    width: 150px;
`;

export default function CustomAnimatedLoadingSpinner(props: BasicProps) {
    return (
        <StyledImg src="/resources/images/loading-spinner.gif" {...props} />
    );
}
