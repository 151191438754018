import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import 'antd/dist/antd.css';
import './resources/app.css';
import './styles/customStyle.css';
import 'react-quill/dist/quill.core.css';

import cartStore from './redux/store';
import UserContextProvider from './context/user/userContextProvider';
import DiscountContextProvider from './context/discount/discountContextProvider';

ReactDOM.render(
    <UserContextProvider>
        <DiscountContextProvider>
            <Provider store={cartStore}>
                <Router>
                    <App />
                </Router>
            </Provider>
        </DiscountContextProvider>
    </UserContextProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(() => {});
