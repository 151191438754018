import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import styled from 'styled-components';
import {
    DropboxOutlined,
    ShoppingCartOutlined,
    SettingOutlined,
    ShakeOutlined,
    UserOutlined,
    HighlightOutlined,
    BookOutlined,
    AppstoreAddOutlined,
    VideoCameraOutlined,
    UserAddOutlined,
    DollarCircleOutlined,
    DashboardOutlined
} from '@ant-design/icons';
import { useContext } from 'react';
import { UserContext } from '../context/user/userContextProvider';

const StyledMenu = styled(Menu)`
.ant-menu-item-group-title {
    margin-bottom: 10px;
    padding-left: 41px;
    text-decoration: underline;
    font: normal normal normal 18px/12px Jost;
    color: #FFFFFF;
}

.ant-menu-item {
    height: 45px;
    margin: 0 !important;
    padding-left: 85px !important;
    color: #FFFFFF;
    span {
        font-size: 20px;
    }
    a {
        padding-left: 10px;
        letter-spacing: 0px;
        font: normal normal normal 16px/10px Jost;
        color: #F0F0F7 !important;
    }
}
`;

export default function AdminSiderMenu(): JSX.Element | null {
    const location = useLocation();
    const { user } = useContext<any>(UserContext);

    if (!user)
        return null;

    return (
        <StyledMenu theme="dark" mode="inline" selectedKeys={[location?.pathname]}>
            {user.accessAreas.indexOf('sales') > -1 && <Menu.ItemGroup title="Sales">
                <Menu.Item key='/admin/dashboard' icon={<DashboardOutlined />}>
                    <Link to='/admin/dashboard'>
                        Dashboard
                    </Link>
                </Menu.Item>
                <Menu.Item key='/admin/customers' icon={<UserOutlined />}>
                    <Link to='/admin/customers'>
                        Customers
                    </Link>
                </Menu.Item>
                <Menu.Item key='/admin/orders' icon={<ShoppingCartOutlined />}>
                    <Link to='/admin/orders'>
                        Orders
                    </Link>
                </Menu.Item>
                <Menu.Item key='/admin/monitoring-plans' icon={<VideoCameraOutlined />}>
                    <Link to='/admin/monitoring-plans'>
                        Monitoring Plans
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>}
            {user.accessAreas.indexOf('inventory') > -1 && <Menu.ItemGroup title="Inventory Management">
                <Menu.Item key='/admin/products' icon={<DropboxOutlined />}>
                    <Link to='/admin/products'>
                        Products
                    </Link>
                </Menu.Item>
                <Menu.Item key='/admin/purchase-orders' icon={<AppstoreAddOutlined />}>
                    <Link to='/admin/purchase-orders'>
                        Purchase Orders
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>}
            {user.accessAreas.indexOf('alula') > -1 && <Menu.ItemGroup title="Alula">
                <Menu.Item key='/admin/devices' icon={<ShakeOutlined />}>
                    <Link to='/admin/devices'>
                        Devices
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>}
            {user.accessAreas.indexOf('info') > -1 && <Menu.ItemGroup title="Informational Pages">
                <Menu.Item key='/admin/blog' icon={<HighlightOutlined />}>
                    <Link to='/admin/blog'>
                        Blog Articles
                    </Link>
                </Menu.Item>
                <Menu.Item key='/admin/knowledge-base' icon={<BookOutlined />}>
                    <Link to='/admin/knowledge-base'>
                        Knowledge Base
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>}
            {user.accessAreas.indexOf('config') > -1 && <Menu.ItemGroup title="Configuration">
                <Menu.Item key='/admin/setup' icon={<SettingOutlined />}>
                    <Link to='/admin/setup'>
                        Integrations
                    </Link>
                </Menu.Item>
                <Menu.Item key='/admin/administrators' icon={<UserAddOutlined />}>
                    <Link to='/admin/administrators'>
                        Administrators
                    </Link>
                </Menu.Item>
                <Menu.Item key='/admin/discount' icon={<DollarCircleOutlined />}>
                    <Link to='/admin/discount'>
                        Discount
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>}
        </StyledMenu>
    );
}
