/* eslint-disable */
import { useState, createContext } from 'react';
import DiscountValues from '../../models/discount';

export const DiscountContext = createContext({});

const DiscountContextProvider: React.FC = ({ children }) => {
    const [allDiscounts, setAllDiscounts] = useState<DiscountValues[]>([]);
    const [globalDiscountIsVisible, setGlobalDiscountIsVisible] = useState(false);
    const [globalDiscountIsActive, setGlobalDiscountIsActive] = useState(false);
    const [globalDiscountValue, setGlobalDiscountValue] = useState(0);

    return (
        <DiscountContext.Provider value={{
            allDiscounts,
            globalDiscountIsVisible,
            globalDiscountIsActive,
            globalDiscountValue,
            setAllDiscounts,
            setGlobalDiscountIsVisible,
            setGlobalDiscountIsActive,
            setGlobalDiscountValue
        }}>
            {children}
        </DiscountContext.Provider>
    );
};

export default DiscountContextProvider;
