/* eslint-disable indent */
import User from '../../models/user';
import * as actionTypes from './actionTypes';

const userReducer = (state: any, action: any): User | null => {
    switch (action.type) {
        case actionTypes.ADD_USER:
            return ({
                id: action.payload.id,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                isAdmin: action.payload.isAdmin,
                accessAreas: action.payload.accessAreas
            });
        case actionTypes.LOG_OUT:
            return null;
        default:
            return null;
    }
};

export default userReducer;
