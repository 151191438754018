import axios from 'axios';

import { BasicProps, Header } from 'antd/lib/layout/layout';
import { useContext, useState } from 'react';
import { Input, message, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import { LOG_OUT } from '../../context/user/actionTypes';
import { UserContext } from '../../context/user/userContextProvider';
import { api } from '../../utils/api';
import { interceptPostError, interceptGetError } from '../../utils/interceptors';
import constants from '../../utils/constants';

interface IProps extends BasicProps, RouteChildrenProps { }

const StyledHeader = styled(Header)`
    height: 116px;
    background-color: ${constants.COLOR.PRIMARY};
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
`;

const StyledDivSearch = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
  
    .ant-input {
        border: none;
        margin-left: 10px;
        text-align: left;
        font: normal normal normal 16px/10px Jost;
        letter-spacing: 0px;
        color: #9B9B9B;
        opacity: 1; 
    }
    .ant-input-affix-wrapper {
        border-radius: 12px;
        height: 52px;
        width: 557px;
    }
`;

const StyledUserName = styled('div')`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 125px;
    height: 42px;
    line-height: 42px;
    color: #fff;
    text-align: center;
    font: normal normal normal 15px/16px Jost;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
`;

const LogOutStyled = styled('div')`
    width: 116px;
    height: 35px;
    margin-left: 15px;
    text-align: left;
    font: normal normal normal 24px/14px Jost;
    letter-spacing: 0px;
    color: #fd8c6d;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export default function CustomAdminHeader(props: IProps) {
    const { user, dispatch } = useContext<any>(UserContext);
    const source = axios.CancelToken.source();

    const [orderNumber, setOrderNumber] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const showPrefixIcon = isSearching ? <Spin /> : <SearchOutlined />;

    const logoutUser = () => {
        api
            .post('/auth/logout')
            .then(() => {
                dispatch({ type: LOG_OUT });
                props.history.push('/');
            })
            .catch(interceptPostError);
    };

    const getOrder = (e: any): void => {
        const find = {
            find: e.target.value,
            findIn: 'orderNumber'
        };

        setIsSearching(true);

        api
            .get('/admin/orders', {
                params: {
                    ...find
                },
                cancelToken: source.token
            })
            .then(res => res.data)
            .then(data => {
                if (data.total === 0) {
                    return message.error('Order was not found');
                }
                return props.history.push(`/admin/orders/${data.orders[0].id}`);
            })
            .catch(interceptGetError)
            .finally(() => {
                setOrderNumber('');
                setIsSearching(false);
            });
    };

    return (
        <StyledHeader>
            <StyledDivSearch >
                <Input
                    prefix={showPrefixIcon}
                    placeholder='Search Order Number Here'
                    value={orderNumber}
                    onPressEnter={getOrder}
                    onChange={(e) => setOrderNumber(e.target.value)}
                />
            </StyledDivSearch>
            <div style={{ display: 'flex', alignItems: 'center', height: 116 }}>
                <StyledUserName><p style={{ marginBottom: 5 }}>{user?.firstName} {user?.lastName}</p><p> Administrator</p></StyledUserName>
                <div
                    style={{
                        width: 145,
                        marginLeft: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer'
                    }}
                    onClick={logoutUser}
                >
                    <LogOutStyled><img src="/resources/images/icon-7.svg" style={{ width: 27, height: 27 }} /><span> Log Out</span></LogOutStyled>
                </div>
            </div>
        </StyledHeader>
    );
}
