export default {
    COLOR: {
        PRIMARY: '#122538',
        ACCENT_COLOR: '#FF8D6D',
        ACTIVE_MENU: '#3b77b7'
    },
    TABLE: {
        PAGE_SIZE: 20,
        WORDS_IN_COLUMN: 10,
        PAGE_SIZE_BLOG_ARTICLES: 9,
        PAGE_SIZE_KNOWLEDGE_LIST: 9,
        PAGE_SIZE_ARTICLE_COMMENTS: 10
    },
    DATE: {
        DATE_TIME_FORMAT: 'MM/DD/YYYY hh:mm',
        DATE_FORMAT: 'MM/DD/YYYY',
        DATE_FORMAT_DOTS_D_M_Y: 'DD.MM.YYYY',
        DATABASE_FORMAT: 'YYYY-MM-DD'
    },
    PHONE_NUMBER_LENGTH: {
        US: 10
    },
    FONT_SIZE: {
        labelSort: 20
    },
    DICTIONARY_CONSTANTS: {
        sections: {
            MAILGUN: 'MAILGUN',
            TWILIO: 'TWILIO',
            MELISSA: 'MELISSA',
            CARDKNOX: 'CARDKNOX',
            ALULA: 'ALULA',
            FEDEX: 'FEDEX',
            NOTIFICATIONS: 'NOTIFICATIONS'
        }
    },
    PURCHASE_ORDER: {
        receivedCreated: 'CREATED',
        receivedWaiting: 'WAITING',
        receivedClosed: 'CLOSED'
    },
    MESSAGE: {
        FIELD_IS_REQUIRED: 'This field is required',
        INCORECT_EMAIL: 'Please enter a valid email address',
        PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
        INCORECT_LENGTH_OF_PHONE: 'Phone number should contain 10 digits',
        INVALID_MASTER_CODE: 'Master code should contain 4 digit'
    },
    AVAILABLE_ZONES: [
        'Bedroom',
        'Garage',
        'Kitchen',
        'Children room',
        'Living room',
        'Basement'
    ],
    LOCAL_STORAGE: {
        orderId: 'orderId',
        orderStep: 'orderStep',
        shoppingCart: 'shoppingCart'
    },
    NEW_CONNECT_PLUS_DEVICE: 'New Connect+ device',
    RESPONSE_STATUSES: {
        INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
        SERVICE_UNAVALIABLE: 'SERVICE_UNAVALIABLE',
        DEVICE_IS_CURRENTLY_ASSIGNED: 'DEVICE_IS_CURRENTLY_ASSIGNED',
        ECONNABORTED: 'ECONNABORTED',
        ENOTFOUND: 'ENOTFOUND',
        USERNAME_IN_USE: 'USERNAME_IN_USE',
        ITEM_NOT_FOUND: 'DEVICE_NOT_FOUND',
        DEVICE_NOT_AVAIABLE: 'DEVICE_NOT_AVAIABLE',
        INVALID_CRC: 'INVALID_CRC',
        INVALID_MAC: 'INVALID_MAC'
    },
    PAYMENT_STATUS: {
        PAID: 'PAID',
        UNPAID: 'UNPAID'
    },
    QUILL: {
        modules: {
            toolbar: {
                container: [
                    [{ header: '1' }, { header: '2' }, { font: [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' },
                    // eslint-disable-next-line indent
                    { indent: '-1' }, { indent: '+1' }],
                    [{ color: [] }, { background: [] }],
                    ['link', 'image', 'video'],
                    [{ align: [] }],
                    ['clean']
                ],
                handlers: {
                    image: () => undefined as void
                }
            },
            clipboard: {
                matchVisual: false
            }
        },
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video', 'color', 'background', 'align'
        ]
    },
    KNOWLEDGE_BASE_POPULAR_TOPICS: ['Products', 'FAQ', 'Topics', 'Payments'],
    BLOG_CATEGORIES: ['Lifestyle', 'Technology', 'Security', 'Automation'],
    BACKGROUND_BLOG_ARTICLES: 'rgb(245,245,245)',
    TRUSTPILOT_ID: '6076fde9f2eabb00013427fd',
    SHOW_LAST_ARTICLES_PRODUCTS: 4,
    SORT_TYPE: { ASC: 'ASC', DESC: 'DESC' },
    LABEL_ORDERS_BUTTONS_STATISTICS: ['Today', 'Last 7 days', 'Last 30 days', 'All'], // remember to change also in backend constants
    PRIVACY_TERMS_OPTIONS: ['Terms of Use', 'Privacy Policy', 'Terms and Conditions', 'Refund Policy', 'Delivery and Shipping Policy'],
    DICOUNT: {
        GLOBAL: 'global'
    },
    US_STATES: {
        AL: 'Alabama',
        AK: 'Alaska',
        AS: 'American Samoa',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'District Of Columbia',
        FM: 'Federated States Of Micronesia',
        FL: 'Florida',
        GA: 'Georgia',
        GU: 'Guam',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MH: 'Marshall Islands',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        MP: 'Northern Mariana Islands',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PW: 'Palau',
        PA: 'Pennsylvania',
        PR: 'Puerto Rico',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VI: 'Virgin Islands',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming'
    },
    STATISTICS_DASHBOARD_CATEGORIES: {
        TYPES: {
            COLLECTED_AT_PURCHASE: 'Collected at Purchase',
            AVERAGE_ORDER_VALUE: 'Average Order Value',
            COST_PER_LEAD: 'Cost Per Lead',
            NEW_ACCOUNTS: 'New Accounts (Sales)',
            PROFIT: 'Profit (initial purchase)',
            COST_PER_ACQUISITION: 'Cost Per Acquisition',
            WEBSITE_VISITORS: 'Website Visitors',
            REVENUE_PER_VISITOR: 'Revenue per Visitor'
        },
        DUMMY_DATA: [
            {
                type: 'Average Order Value',
                value: 67058,
                percentage: 7.8
            },
            {
                type: 'Collected at Purchase',
                value: 127.49,
                percentage: -10.9
            },
            {
                type: 'Cost Per Lead',
                value: 1000,
                percentage: -3.9
            },
            {
                type: 'New Accounts (Sales)',
                value: 526,
                percentage: 20.9
            },
            {
                type: 'Profit (initial purchase)',
                value: -53645,
                percentage: -22.2
            },
            {
                type: 'Cost Per Acquisition',
                value: 2000,
                percentage: -2.7
            },
            {
                type: 'Website Visitors',
                value: 71374,
                percentage: 8.3
            },
            {
                type: 'Revenue per Visitor',
                value: 0.93,
                percentage: 0.08
            }
        ],
        DUMMY_ANALYTICS: [
            {
                type: 'Average Order Value',
                value: 67058,
                percentage: 7.8
            },
            {
                type: 'Collected at Purchase',
                value: 127.49,
                percentage: -10.9
            },
            {
                type: 'Cost Per Lead',
                value: 1000,
                percentage: -3.9
            },
            {
                type: 'New Accounts (Sales)',
                value: 526,
                percentage: 20.9
            }
        ]
    }
};
