import styled from 'styled-components';
import CustomAnimatedLoadingSpinner from './CustomAnimatedLoadingSpinner';

const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    position: relative;
    z-index: 2147483648;
`;

export default function CustomFullPageLoading() {
    return (
        <Loader>
            <CustomAnimatedLoadingSpinner />
        </Loader>
    );
}
