import styled from 'styled-components';
import { Layout } from 'antd';

const StyledLayout = styled(Layout)`
    .logo {
        float: left;
        width: 120px;
        height: 31px;
        margin: 16px 24px 16px 24px;
        background: rgba(255,255,255,0.3);
        text-align: center;
        line-height: 31px;
    }

    .ant-row-rtl #components-layout-demo-top-side-2 .logo {
        float: right;
        margin: 16px 0 16px 24px;
    }

    .site-layout-background {
        background: #fff;
    }

    .header__fixed {
        position: relative;
        width: 100%;

        img {
            display: none;
        }

        .header__container {
            justify-content: flex-end;
        }
    }
`;

export default StyledLayout;
