import * as actionTypes from './actionTypes';

type CartReducer = {
    productsInCart: number;
};

const initialState: CartReducer = {
    productsInCart: 0
};

type CartAction = CartReducer & {
    type: string;
};

const reducer = (state: CartReducer = initialState, action: CartAction): CartReducer => {
    switch (action.type) {
    case actionTypes.CHANGE_SELECTED_PRODUCTS_AMOUNT:
        return {
            ...state,
            productsInCart: action.productsInCart || 0
        };

    default:
        return state;
    }
};

export default reducer;
