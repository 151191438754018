import { SiderProps } from 'antd/lib/layout';
import { Layout } from 'antd';
import styled from 'styled-components';
import constants from '../utils/constants';

const { Sider } = Layout;

const CustomSider = styled(Sider)`
    background-color: ${constants.COLOR.PRIMARY};
    min-height: 100vh;
    
    .ant-menu.ant-menu-dark {
        background-color: ${constants.COLOR.PRIMARY};
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: ${constants.COLOR.ACTIVE_MENU};
    }

    img {
        width: 50%;
        height: 53px;
        display: block;
        margin: 26px 20px;
        padding-left: 21px;
    }
`;

const StyledSider = (props: SiderProps) => (
    <CustomSider {...props}>
        {props.children}
    </CustomSider>
);

export default StyledSider;
