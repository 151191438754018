import { CustomModal } from '../custom';

function displayUnathorizedReourceAlert() {
    CustomModal.displayCustomWarning({
        title: 'Authorization problem',
        content: 'Sorry, but you don\'t have access to this resource. Please try one more time. If the error occurs, then log in one more time.'
    });
}

function displayServiceUnavaliableAlert(serviceName: string) {
    CustomModal.displayCustomWarning({
        title: `${serviceName} is unavaliabe`,
        content: 'Sorry, but you can\'t do this action at the moment. External service is unavaliable. Please try again later.'
    });
}

export {
    displayUnathorizedReourceAlert,
    displayServiceUnavaliableAlert
};
