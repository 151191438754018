/* eslint-disable no-unneeded-ternary */

import IUser from '../models/user';

function isAuthenticated(user: IUser): boolean {
    return user?.id ? true : false;
}

function isAdmin(user: IUser): boolean {
    return user?.isAdmin;
}

export {
    isAuthenticated,
    isAdmin
};
