import { useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { UserContext } from '../context/user/userContextProvider';
import { isAuthenticated } from '../session/user';
import NotFoundView from '../views/admin/NotFoundView';

interface IProps extends RouteProps {
    rules?: boolean;
    area?: string;
}

export default function PrivatePath(props: IProps) {
    const { user } = useContext<any>(UserContext);
    const location = useLocation();

    if (!isAuthenticated(user)) {
        return <Redirect to={`/?redirect=${location.pathname}`} />;
    }

    if (props.area && user.accessAreas.indexOf(props.area) === -1) {
        return <NotFoundView homeLink="/admin/dashboard" />;
    }

    if (props.rules !== undefined && props.rules === false) {
        return <Redirect to={`/?redirect=${location.pathname}`} />;
    }

    return (
        <Route {...props}>
            {props.children}
        </Route>
    );
}
