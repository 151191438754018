/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext, Suspense } from 'react';
import {
    Route, Switch, RouteComponentProps, withRouter, useLocation
} from 'react-router-dom';
import { Layout } from 'antd';

import axios from 'axios';
import { api } from './utils/api';
import AdminTemplate from './templates/AdminTemplate';
import { UserContext } from './context/user/userContextProvider';
import { ADD_USER } from './context/user/actionTypes';
import PrivatePath from './components/PrivatePath';
import { isAdmin } from './session/user';
import CustomFullPageLoading from './custom/CustomFullPageLoading';
import StyledLayout from './styles/StyledLayout';

const Dashboard = React.lazy(() => import('./views/admin/Dashboard'));
const OrderDetailsView = React.lazy(() => import('./views/admin/OrderDetailsView'));
const OrdersView = React.lazy(() => import('./views/admin/OrdersView'));
const ProductsView = React.lazy(() => import('./views/admin/ProductsView'));
const ProductView = React.lazy(() => import('./views/admin/ProductView'));
const PurchaseOrderCreate = React.lazy(() => import('./views/admin/PurchaseOrderCreateView'));
const PurchaseOrderView = React.lazy(() => import('./views/admin/PurchaseOrderView'));
const PurchaseOrders = React.lazy(() => import('./views/admin/PurchaseOrdersView'));
const SetupView = React.lazy(() => import('./views/admin/SetupView'));
const DeviceCreateView = React.lazy(() => import('./views/admin/DeviceCreateView'));
const DevicesView = React.lazy(() => import('./views/admin/DevicesView'));
const CustomerView = React.lazy(() => import('./views/admin/CustomerView'));
const CustomersView = React.lazy(() => import('./views/admin/CustomersView'));
const BlogArticleCreateView = React.lazy(() => import('./views/admin/BlogArticleCreateView'));
const BlogArticleEditView = React.lazy(() => import('./views/admin/BlogArticleEditView'));
const BlogView = React.lazy(() => import('./views/admin/BlogView'));
const CreateKnowledgeBaseRecord = React.lazy(() => import('./views/admin/CreateKnowledgeBaseRecordView'));
const EditKnowledgeBaseRecordView = React.lazy(() => import('./views/admin/EditKnowledgeBaseRecordView'));
const AdminKnowledgeBaseView = React.lazy(() => import('./views/admin/KnowledgeBaseView'));
const MonitoringPlansView = React.lazy(() => import('./views/admin/MonitoringPlansView'));
const AdministratorRegisterView = React.lazy(() => import('./views/admin/AdministratorRegisterView'));
const AdministratorEditView = React.lazy(() => import('./views/admin/AdministratorEditView'));
const AdministratorsView = React.lazy(() => import('./views/admin/AdministratorsView'));
const DiscountView = React.lazy(() => import('./views/admin/DiscountView'));
const LoginView = React.lazy(() => import('./views/admin/LoginView'));
const NotFoundView = React.lazy(() => import('./views/admin/NotFoundView'));

const App = (props: RouteComponentProps): JSX.Element => {
    const source = axios.CancelToken.source();
    const location = useLocation();

    const [isLoadingUser, setIsLoadingUser] = useState(true);

    const { user, dispatch } = useContext<any>(UserContext);

    const getCurrentLoggedUser = (): void => {
        api
            .get('/auth/user', { cancelToken: source.token })
            .then(res => res.data)
            .then(data => {
                if (data.user) {
                    dispatch({ type: ADD_USER, payload: data.user });
                }
            })
            .finally(() => setIsLoadingUser(false));
    };

    useEffect(() => {
        getCurrentLoggedUser();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    if (isLoadingUser) {
        return <CustomFullPageLoading />;
    }

    return (
        <Switch>
            <PrivatePath rules={isAdmin(user)} path='/admin'>
                <AdminTemplate {...props}>
                    <Suspense fallback={<CustomFullPageLoading />}>
                        <Switch>
                            <PrivatePath path='/admin/dashboard' component={Dashboard} />
                            <PrivatePath area="sales" path='/admin/orders/:id' component={OrderDetailsView} />
                            <PrivatePath area="sales" path='/admin/orders' component={OrdersView} />
                            <PrivatePath area="inventory" path='/admin/products' render={() => <ProductsView user={user} {...props} />} />
                            <PrivatePath area="inventory" path='/admin/product/:id' component={ProductView} />
                            <PrivatePath area="inventory" path='/admin/product/create' component={ProductView} />
                            <PrivatePath area="inventory" path='/admin/purchase-orders/create' component={PurchaseOrderCreate} />
                            <PrivatePath area="inventory" path='/admin/purchase-orders/:id' component={PurchaseOrderView} />
                            <PrivatePath area="inventory" path='/admin/purchase-orders' component={PurchaseOrders} />
                            <PrivatePath area="config" path='/admin/setup' component={SetupView} />
                            <PrivatePath area="alula" path='/admin/devices/create' component={DeviceCreateView} />
                            <PrivatePath area="alula" path='/admin/devices' component={DevicesView} />
                            <PrivatePath area="sales" path='/admin/customers/:id' component={CustomerView} />
                            <PrivatePath area="sales" path='/admin/customers' component={CustomersView} />
                            <PrivatePath area="info" path='/admin/blog/create' component={BlogArticleCreateView} />
                            <PrivatePath area="info" path='/admin/blog/:id' component={BlogArticleEditView} />
                            <PrivatePath area="info" path='/admin/blog' component={BlogView} />
                            <PrivatePath area="info" path='/admin/blog' render={() => <BlogView user={user} {...props} />} />
                            <PrivatePath area="info" path='/admin/knowledge-base/create' render={() => <CreateKnowledgeBaseRecord {...props} />} />
                            <PrivatePath area="info" path='/admin/knowledge-base/:id' component={EditKnowledgeBaseRecordView} />
                            <PrivatePath area="info" path='/admin/knowledge-base' render={() => <AdminKnowledgeBaseView user={user} {...props} />} />
                            <PrivatePath area="sales" path='/admin/monitoring-plans' component={MonitoringPlansView} />
                            <PrivatePath area="config" path='/admin/administrators/register' component={AdministratorRegisterView} />
                            <PrivatePath area="config" path='/admin/administrators/:id' component={AdministratorEditView} />
                            <PrivatePath area="config" path='/admin/administrators' component={AdministratorsView} />
                            <PrivatePath area="config" path='/admin/discount' component={DiscountView} />
                            <PrivatePath path="*" render={() => <NotFoundView homeLink="/admin/orders" />} />
                        </Switch>
                    </Suspense>
                </AdminTemplate>
            </PrivatePath>
            <Route path='/'>
                <StyledLayout>
                    <Layout className="site-layout" style={{ height: '100vh' }}>
                        <Suspense fallback={<CustomFullPageLoading />}>
                            <Switch>
                                <Route exact path='/' component={LoginView} />
                            </Switch>
                        </Suspense>
                    </Layout>
                </StyledLayout>
            </Route>
        </Switch>
    );
};

export default withRouter(App);
