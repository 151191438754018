import { Link } from 'react-router-dom';

interface IProps {
    homeLink?: string;
}

export default function NotFoundView({ homeLink }: IProps) {
    return (
        <main className="main main_flex" style={{ marginTop: 100 }}>
            <section className="s-404">
                <div className="container s-404__container"> <img className="s-404__img" src="/resources/images/oops.svg" alt="" width="635" height="445" />
                    <h1 className="h1 s-404__title">Oops something is missing</h1>
                    <p className="s-404__txt">Each wireless motion sensor is pre-programmed with your Cove Touch security panel. You can customize each motion sensor name in your alarm kit to correlate with its location within your home. With a couple touches of the screen, you can confirm each motion sensor is setup properly and ready to start providing worry free security for the ones you love.</p>
                    <Link className="btn" to={homeLink || '/'}>BACK TO HOME</Link>
                </div>
            </section>
        </main>
    );
}
